<template>
  <div class="login">
    <div class="logo">
      <img
        @error="imageError"
        :src="`${$store.state.assetsUrl}/imgs/login/logo.webp`"
        alt="logo"
      />
    </div>
    <div class="welcome">{{ $t("Welcome") }}</div>
    <div class="question">{{ $t("HaveAccountQuestion") }}</div>
    <div class="go_sign" @click="$router.push('/consultation')">
      {{ $t("StartTheSurvey") }}
    </div>
    <div class="login_form">
      <div class="login_form_item">
        <label for="email" class="login_form_item_label">{{
          $t("email")
        }}</label>
        <div class="login_form_item_input">
          <input
            type="email"
            @keydown="checkEnter"
            @blur="blur('email')"
            @focus="focus('email')"
            id="email"
            :placeholder="$t('EnterYourEmail')"
            v-model="email"
          />
        </div>
        <div class="login_form_item_error" v-if="emailError">
          {{ emailError }}
        </div>
      </div>
      <div class="login_form_item">
        <label for="password" class="login_form_item_label">{{
          $t("password")
        }}</label>
        <div class="login_form_item_input">
          <input
            @keydown="checkEnter"
            @blur="blur('password')"
            @focus="focus('password')"
            :type="close ? 'password' : 'text'"
            maxlength="32"
            id="password"
            :placeholder="$t('EnterYourPassword')"
            v-model="password"
          />
          <div class="eye">
            <img
              @error="imageError"
              class="close"
              v-show="password.length"
              @click="close = !close"
              :src="
                close
                  ? `${$store.state.assetsUrl}/imgs/login/close.webp`
                  : `${$store.state.assetsUrl}/imgs/login/open.webp`
              "
              alt="close"
            />
          </div>
        </div>
        <div class="tips">
          <div v-if="passwordError" class="error_text">{{ passwordError }}</div>
          <div v-if="password.length == 32" class="max_length">
            <span>32/32</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="payment_successful_button"
      :class="{ can_not_next: !canSend() }"
      @click="login"
    >
      {{ $t("login") }}
    </div>
    <loading :showCover="isLoading"></loading>
  </div>
</template>

<script>
import * as firebase from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { login } from "@/api/index.js";
import { common } from "../assets/utils/common";
import Loading from "@/components/cover_loading.vue";
import { Toast } from "vant";
import { commonMixin } from "../assets/mixin/common";
export default {
  name: "login",
  components: { Loading },
  mixins: [commonMixin],
  data() {
    return {
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      close: true,
      emailIsFocus: false,
      isLoading: false,
      // 用户登录
      firebaseConfig: {},
    };
  },
  created() {
    this.$store.commit("setIsApp", true);
    // 配置环境变量
    this.firebaseConfig =
      process.env.NODE_ENV != "development"
        ? common.proFirebase
        : common.devFirebase;
  },
  methods: {
    checkEnter(e) {
      if (e.keyCode == 13) {
        this.login();
      }
    },
    canSend() {
      if (common.isEmail(this.email) && this.password.length >= 6) return true;
      return false;
    },
    blur(type) {
      if (type == "email") {
        // 检测email是否符合要求
        if (!common.isEmail(this.email)) {
          this.emailError = this.$t("EmailError");
        }
      } else {
        // 检测password是否符合要求
        if (this.password.length < 6) {
          this.passwordError = this.$t("password_tips");
        }
      }
    },
    focus(type) {
      if (type == "email") {
        this.emailError = "";
      } else {
        this.passwordError = "";
      }
    },
    login() {
      if (!this.canSend()) return;
      this.isLoading = true;
      firebase.initializeApp(this.firebaseConfig);
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          login({ token: user.accessToken })
            .then((res) => {
              this.isLoading = false;
              if (res.errorCode) {
                if (res.errorCode == 100000) {
                  Toast(this.$t("NetworkError"));
                  return;
                }
                this.passwordError = this.$t("PasswordError");
                return;
              }
              this.$changeCookie.setCookie(
                "loginToken",
                res.token,
                process.env.VUE_APP_TOKEN_TIMEOUT
              );
              this.$store.commit("setToken", res.token);
              this.$changeLocal.setItem("userInfo", res.user_info);
              this.$router.push("/my-account");
            })
            .catch(() => {
              this.isLoading = false;
              Toast(this.$t("NetworkError"));
            });
        })
        .catch((e) => {
          this.isLoading = false;
          if (
            e.message.indexOf("wrong-password") != -1 ||
            e.message.indexOf("user-not-found") != -1
          ) {
            this.passwordError = this.$t("PasswordError");
          } else {
            Toast(this.$t("NetworkError"));
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.login {
  padding: 0 20px;
  max-width: 765px;
  margin: 0 auto;

  .logo {
    height: 60px;
    padding-left: 4px;
    margin-top: 28px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 97px;
      height: 37px;
    }
  }

  .welcome {
    margin-top: 21px;
    font-size: 24px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #212121;
    line-height: 33px;
  }

  .question {
    margin: 10px auto 4px;
    font-size: 12px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #999999;
    line-height: 18px;
  }

  .go_sign {
    font-size: 12px;
    font-family: "AvenirNext";
    font-weight: 700;
    color: #444444;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
  }

  .login_form {
    text-align: left;
    margin-top: 34px;

    .login_form_item {
      margin-top: 15px;

      .login_form_item_label {
        font-size: 15px;
        font-family: Helvetica;
        color: #999999;
        line-height: 18px;
      }

      .login_form_item_input {
        position: relative;
        margin-top: 10px;

        .close {
          position: absolute;
          right: 12px;
          width: 15px;
          height: 16px;
          top: 50%;
          transform: translate(0, -50%);
          cursor: pointer;
        }
      }

      input {
        height: 52px;
        background: #f9f9f9;
        border-radius: 10px;
        border: 1px solid #dddddd;
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        font-size: 15px;
        font-family: Helvetica;
        color: #333333;
        line-height: 18px;
      }

      .login_form_item_error {
        font-size: 12px;
        font-family: "AvenirNext";
        font-weight: 500;
        color: #e85345;
        line-height: 16px;
        margin-top: 10px;
      }

      .login_form_item_error {
        font-size: 12px;
        margin: 10px 0 0;
        font-family: "AvenirNext";
        font-weight: 500;
        color: #e85345;
        line-height: 16px;
      }

      .tips {
        display: flex;

        .error_text {
          font-size: 12px;
          margin: 10px 0 0;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #e85345;
          line-height: 16px;
        }

        .max_length {
          position: relative;
          margin: 10px 0 0;
          flex: 1;
          height: 16px;

          span {
            position: absolute;
            right: 10px;
            font-size: 12px;
            font-family: "AvenirNext";
            font-weight: 500;
            color: #e3574b;
            line-height: 16px;
          }
        }
      }
    }
  }

  .terms_and_privacy {
    display: flex;
    margin-top: 10px;
    text-align: left;

    div {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #bbbbbb;
      line-height: 19px;
      margin-right: 4px;
    }

    span {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #bbbbbb;
      line-height: 17px;

      i {
        font-style: normal;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .payment_successful_button {
    height: 55px;
    background: #fd7c61;
    border-radius: 55px;
    font-size: 16px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 200px auto 140px;
    cursor: pointer;
  }

  .can_not_next {
    opacity: 0.3;
  }

  input::-webkit-input-placeholder {
    color: #bbb;
    font-size: 14px;
  }
}
</style>
