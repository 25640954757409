//对于axios进行二次封装
import axios from "axios";
import store from '@/store/index.js'
import router from '@/router/index.js'
import ChangeCookie from '@/assets/utils/change_cookie.js'

// 因为这个项目里有注册和登录，并且注册的部分页面也需要用到token，所以将两个token分开，对应不同的接口使用
const signTokenUrl = ['/subscriptions/initiate', '/auth/change-pwd', '/goods/lists', '/user/success-subscriptions'];
//  从登陆页面进入的时候，token走这里
const loginTokenUrl = ['/user/subscriptions', '/user/cancel-subscriptions','/user/delete'];

//底下的代码也是创建axios实例
let requests = axios.create({
    //基础路径
    baseURL: "/wapi",
    //请求超时
    timeout: 100000,
});


//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
requests.interceptors.request.use((config) => {
    config.headers['fh'] = JSON.stringify(
        {
            "gmt_offset": store.state.gmtOffset,
            "app_version_code": 102,
            "language": "en",
            "device_type": 5
        }
    )
    if (signTokenUrl.includes(config.url) || loginTokenUrl.includes(config.url)) {
        if (signTokenUrl.includes(config.url)) {
            config.headers['authorization'] = `Bearer ${store.state.signToken}`;
        } else {
    config.headers['authorization'] = `Bearer ${store.state.token}`
        }
    } else {
    config.headers['authorization'] = undefined;
    }
    return config;
});

//响应拦截器----当服务器手动请求之后，做出响应（相应成功）会执行的
requests.interceptors.response.use(
    (res) => {
        if (!res || !res.data) return { error: '服务器响应数据失败', errorCode: 100000 };
        return res.data.data;
    },
    (error) => {
        let res = error.response;
        if (!res || !res.data) return { error: '服务器响应数据失败', errorCode: 100000 };
        if (res.data.code == 401) {
            // token有注册和登录之分，在注册页面如果token认证失败就toast，而在登录页面认证失败就清除token跳转到登录页。
            const changeCookie = new ChangeCookie();
            if (loginTokenUrl.includes(res.config.url)) {
                store.commit('setToken', '');
                changeCookie.clearCookie('loginToken');
                localStorage.removeItem('userInfo');
                router.push('/login');
            } else if (signTokenUrl.includes(res.config.url)) {
                localStorage.removeItem('localList');
                localStorage.removeItem('localPlan');
                changeCookie.clearCookie('step');
                changeCookie.clearCookie('registerToken');
                store.commit('setSignToken', '');
                router.push('/consultation');
            }
            return { error: '认证失败', errorCode: 401 };
        } else if (res.data.code == 400) {
            return { error: '参数错误', errorCode: 400 };
        } else if (res.data.code == 4003) {
            return { error: '邮箱已注册', errorCode: 4003 };
        } else if (res.data.code == 4053) {
            return { error: '用户订阅中', errorCode: 4053 };
        } else if (res.data.code == 5005) {
            return { error: '重复提交', errorCode: 5005 };
        } else if (!res.data.data) {
            return { error: '服务器响应数据失败', errorCode: 100000 };
        } else if (res.data.data.code == 4010) {
            return { error: '用户未找的', errorCode: 4010 };
        } else if (res.data.data.code == 4016) {
            return { error: 'firebase连接失败', errorCode: 4016 };
        } else {
            return { error: '服务器响应数据失败', errorCode: 100000 };
        }
    }
);
//最终需要对外暴露（不对外暴露外面模块没办法使用）
//这里的代码是暴露一个axios实例
export default requests;