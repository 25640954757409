export default {
    register: '/auth/register',
    initInfo: '/init/info',
    goodsLists: '/goods/lists',
    changePassword: '/auth/change-pwd',
    login: '/auth/login',
    deleteAccount: '/user/delete',
    initiate: '/subscriptions/initiate',
    userSubscription:'/user/subscriptions',
    subscriptionsSuccessful:'/user/success-subscriptions',
    cancelSubscriptions:'/user/cancel-subscriptions'
}