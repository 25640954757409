<template>
    <van-overlay :show="showCover">
        <div class="loading">
            <div class="loading_wrap">
            </div>
        </div>
    </van-overlay>
</template>

<script>
export default {
    name: 'cover_loading',
    props: {
        showCover: {
            type: Boolean,
            default: () => false,
        }
    },
    created() {
    },
    watch: {

    }
}
</script>

<style scoped lang="less">
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .loading_wrap {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 6px solid #fff;
        border-left-color: #fd7c61;
        animation: loading 1s linear infinite;

    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
</style>