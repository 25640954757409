//统一管理项目接口的模块
import requests from "./ajax";
import Url from '@/api/url.js'

//获取初始信息
export const requestInitInfo = () => requests({
    url: Url.initInfo,
    method: 'get',
});

//注册
export const requestRegister = (data) => requests({
    url: Url.register,
    method: 'post',
    data: data
});

// 获取价格信息
export const requestPlanPrice = (data) => requests({
    url: Url.goodsLists,
    method: 'post',
    data: data
})

// 删除账户
export const deleteAccount = () => requests({
    url: Url.deleteAccount,
    method: 'post',
})

// 修改密码
export const changePassword = (data) => requests({
    url: Url.changePassword,
    method: 'post',
    data: data
})

// 登录 
export const login = (data) => requests({
    url: Url.login,
    method: 'post',
    data: data
})

// 请求用户的订阅列表
export const userSubscription = (data) => requests({
    url: Url.userSubscription,
    method: 'get',
    params: data
})

// 发送订阅信息
export const sendSubscriptionInfo = (data) => requests({
    url: Url.initiate,
    method: 'post',
    data: data
})

// 成功订阅
export const subscriptionsSuccessful = (data) => requests({
    url: Url.subscriptionsSuccessful,
    method: 'post',
    data: data
})

// 取消订阅
export const cancelSubscriptions = (data) => requests({
    url: Url.cancelSubscriptions,
    method: 'post',
    data: data,
})